export const config = {
  url: process.env.VUE_APP_API_URL,
  businessUrl: process.env.VUE_APP_BUSINESS_URL,
  customerUrl: process.env.VUE_APP_CUSTOMER_URL,
  env: process.env.NODE_ENV,
};

export const apiResource = {
  checkBusinessSlug: "/GXn2Zf6STySI6DYXCvI7vgnVAGZOOktrWR0xb5ug2sE=",
  login: "/RPHjaHaN0lzBFKD-qb5-luWdSyzbpL4ndE855BPYJ7o=",
  loginOTP: "/PFQy4S8R0Gi60_rrWs006RYpGlO2oaeKQYUPrME_eTA=",
  resendOTP: "/Edva9Fce3gUQggvnu7YnjF4fpr3KUetsmuNlWHDQ6ss=",
  profile: "/eLn2RoNXN-_jF2vQcoONww1qigeEQou8q3Dv8067L7I=",
  getBusinessSchedules: "/oNKfdlt5siJurhPKCUwMZvD6V89cVaQVGKqIAWQd54M=",
  getScheduleDetails: "/eWHvaSmcPOWiHvnGiRPPRI-cC0-CRUX4I7IivQBxEog=",
  downloadReport: "/bMBAkRpcRPVoXMC5J6D3vQQii0bI-k9LcmSQN-TFJAc=",
};
